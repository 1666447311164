export default [
  {
    path: '/settings/settings-dashboard',
    name: 'settings-dashboard',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/settings-general/general',
    name: 'settings-general',
    component: () => import('@/views/settings/General/General.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/departments',
    name: 'settings-departments',
    component: () => import('@/views/settings/Department/Department.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/labs',
    name: 'settings-labs',
    component: () => import('@/views/settings/Lab/Lab.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/materials',
    name: 'settings-materials',
    component: () => import('@/views/settings/Material/Material.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/material-add',
    name: 'settings-material-add',
    component: () => import('@/views/settings/Material/MaterialAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/material-edit/:id',
    name: 'settings-material-edit',
    component: () => import('@/views/settings/Material/MaterialEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/objectcodes',
    name: 'settings-objectcodes',
    component: () => import('@/views/settings/ObjectCode/ObjectCode.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/objectcode-add',
    name: 'settings-objectcode-add',
    component: () => import('@/views/settings/ObjectCode/ObjectCodeAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/objectcode-edit/:id',
    name: 'settings-objectcode-edit',
    component: () => import('@/views/settings/ObjectCode/ObjectCodeEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/survey',
    name: 'settings-survey',
    component: () => import('@/views/settings/Survey/Survey.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },

  {
    path: '/settings/settings-dashboard/terms',
    name: 'settings-terms',
    component: () => import('@/views/settings/Terms/Terms.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },
  {
    path: '/settings/settings-dashboard/note',
    name: 'settings-note',
    component: () => import('@/views/settings/Note/Note.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'settings-dashboard',
    },
  },
];
