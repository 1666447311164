export default [
  {
    path: '/project/project-list',
    name: 'project-list',
    component: () => import('@/views/project/ProjectList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/project/project-preview/:id',
    name: 'project-preview',
    component: () => import('@/views/project/ProjectPreview.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'project-list',
    },
  },

  {
    path: '/project/project-add',
    name: 'project-add',
    component: () => import('@/views/project/ProjectAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'project-preview',
    },
  },

  {
    path: '/project/project-edit/:id',
    name: 'project-edit',
    component: () => import('@/views/project/ProjectEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'project-preview',
    },
  },

  {
    path: '/project/generate-quote/:id',
    name: 'generate-quote',
    component: () => import('@/views/project/GenerateQuote.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'project-list',
    },
  },

  {
    path: '/project/generate-ticket/:id',
    name: 'generate-ticket',
    component: () => import('@/views/project/GenerateTicket.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'project-list',
    },
  },

  

  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/dashboard/Report.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/employee-hours',
    name: 'employee-hours',
    component: () => import('@/views/billing/EmployeeHours.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/materials-used',
    name: 'materials-used',
    component: () => import('@/views/billing/MaterialsUsed.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
