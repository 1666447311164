export default [
  {
    path: '/billing/billing-history',
    name: 'billing-history',
    component: () => import('@/views/billing/BillingCreate.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/billing/billing-create',
    name: 'billing-create',
    component: () => import('@/views/billing/BillingCreate.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/billing/billing-approval',
    name: 'billing-approval',
    component: () => import('@/views/billing/BillingCreate.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/billing/billing-confirmation',
    name: 'billing-confirmation',
    component: () => import('@/views/billing/BillingCreate.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },




];
