export default [
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
      Permission: "no_permission_required",
    },
  },

  {
    path: "/our-mantra",
    name: "our-mantra",
    component: () => import("@/views/OurMantra.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      Permission: "no_permission_required",
    },
  },


  {
    path: "/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/error/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      Permission: "no_permission_required",
    },
  },

  {
    path: "/logout",
    name: "logout",
    component: () => {
      //   store.commit("app/CLEAR_TOKEN");
      //  localStorage.removeItem("token");
      //  localStorage.removeItem("expirationDate");
      //  router.push({ name: "login" });
    },
    meta: {
      Permission: "no_permission_required",
    },
  },
];
