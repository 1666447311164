export default [
  {
    path: "/user/user-list",
    name: "user-list",
    component: () => import("@/views/user/user-list/UserList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/user/user-edit/:id",
    name: "user-edit",
    component: () => import("@/views/user/user-add/UserEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/user/user-add",
    name: "user-add",
    component: () => import("@/views/user/user-add/UserAdd.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/user/user-profile",
    name: "user-profile",
    component: () => import("@/views/user/user-profile/AccountSetting.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/user/weekly-timesheet",
    name: "weekly-timesheet",
    component: () => import("@/views/user/weekly-timesheet/Timesheet.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
