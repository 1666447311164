export default [
  {
    path: "/customer/customer-list",
    name: "customer-list",
    component: () => import("@/views/customer/customer-list/CustomerList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },


  {
    path: "/customer/customer-edit",
    name: "customer-edit",
    props: true,
    component: () => import("@/views/customer/customer-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },


];
