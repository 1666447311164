import Vue from 'vue';

// axios
import axios from 'axios';
let auth = '';
if (localStorage.getItem('token')) {
  auth = 'Bearer ' + localStorage.getItem('token');
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================

  baseURL: 'https://api.harvard.systemstailormade.com/public/api',
  // baseURL: 'http://127.0.0.1:8000/api',

  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
